/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$black: #0a0a0a;    
$default-primary-base-color: #333;
$default-primary-dark-color: #222;
$default-primary-light-color: #444;
$default-secondary-base-color: #ededed;
$default-secondary-dark-color: #333;
$default-secondary-light-color: #f0f0f0;
$font-muli: 'Nunito', sans-serif;
$font-standard-size: 62.5%;

